<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">{{ l('编辑社保类别') }}</span>
					<span v-else>{{ l('新增社保类别') }}</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('编码')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-input placeholder="编码" v-model="entity.code" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('名称')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-input placeholder="名称" v-model="entity.name" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('备注')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-textarea placeholder="备注" v-model="entity.remark" />
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save()">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	CreateOrUpdateSocialSecurityCategoryInput,
	SocialSecurityCategoryEditDto,
	SocialSecurityCategoryServiceProxy,
} from '../../../../shared/service-proxies';

export default {
	name: 'create-or-edit-ss-rules',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			id: undefined,
			entity: new SocialSecurityCategoryEditDto(),
		};
	},
	created() {
		this.fullData();
		this._socialSecurityCategoryServiceProxy = new SocialSecurityCategoryServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._socialSecurityCategoryServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.socialSecurityCategory;
				});
		},
		save() {
			if (!this.entity.code) {
				return abp.message.warn('编码不能为空');
			}
			if (!this.entity.name) {
				return abp.message.warn('名称不能为空');
			}
			let input = new CreateOrUpdateSocialSecurityCategoryInput();
			input.socialSecurityCategory = this.entity;
			this.loading = true;
			this._socialSecurityCategoryServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
