<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">{{ l('编辑社保明细') }}</span>
					<span v-else>{{ l('新增社保明细') }}</span>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="5" class="text-right">
						<span style="color: red">*</span>
						<span style="color: black">编码：</span>
					</a-col>
					<a-col :span="7">
						<a-input type="text" v-model="entity.code" disabled class="disabled" />
					</a-col>
					<a-col :span="5" class="text-right">
						<span style="color: red">*</span>
						<span style="color: black">名称：</span>
					</a-col>
					<a-col :span="7">
						<a-input type="text" v-model="entity.name" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5" class="text-right">
						<span style="color: red">*</span>
						<span style="color: black">比例：</span>
					</a-col>
					<a-col :span="7">
						<a-input-number
							class="width100"
							step="1"
							:formatter="(value) => `${value}%`"
							v-model="entity.rate"
						/>
					</a-col>
					<a-col :span="5" class="text-right">
						<span style="color: black">调整金额：</span>
					</a-col>
					<a-col :span="7">
						<a-input class="width100" v-model="entity.adjustAmount" />
					</a-col>
				</a-row>
				<!--                <a-row>-->
				<!--                    <a-col :span="5" class="text-right">-->
				<!--                        <span style="color: black">上限：</span>-->
				<!--                    </a-col>-->
				<!--                    <a-col :span="7">-->
				<!--                        <a-input class="width100"  v-model="entity.upperLimit"/>-->
				<!--                    </a-col>-->
				<!--                    <a-col :span="5" class="text-right">-->
				<!--                        <span style="color: black">下限：</span>-->
				<!--                    </a-col>-->
				<!--                    <a-col :span="7">-->
				<!--                        <a-input class="width100"  v-model="entity.lowerLlimit"/>-->
				<!--                    </a-col>-->
				<!--                </a-row>-->
				<a-row>
					<a-col :span="5" class="text-right">
						<span style="color: black">备注：</span>
					</a-col>
					<a-col :span="19">
						<a-input type="textarea" :line="3" v-model="entity.remark" />
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save()">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	CreateOrUpdateSocialSecurityDetailInput,
	SocialSecurityDetailEditDto,
	SocialSecurityDetailServiceProxy,
} from '../../../../shared/service-proxies';
import ObjectUtils from '../../../../shared/utils/ObjectUtils';

export default {
	name: 'create-or-edit-ss-detail',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			//明细ID
			id: undefined,
			//分类ID
			ssId: undefined,
			entity: new SocialSecurityDetailEditDto(),
		};
	},
	created() {
		this.fullData();
		this._socialSecurityDetailServiceProxy = new SocialSecurityDetailServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._socialSecurityDetailServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then(({ socialSecurityDetail }) => {
					this.entity = ObjectUtils.initDto(socialSecurityDetail);
				});
		},
		save() {
			if (!this.entity.code) {
				abp.message.warn('请输入编码');
				return;
			}
			if (!this.entity.name) {
				abp.message.warn('请输入名称');
				return;
			}
			if (!this.entity.rate || this.entity.rate === 0) {
				abp.message.warn('请输入比例');
				return;
			}

			this.entity.id = this.id;
			this.entity.ssId = this.ssId;

			let input = new CreateOrUpdateSocialSecurityDetailInput();
			input.socialSecurityDetail = this.entity;
			this.loading = true;
			this._socialSecurityDetailServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.width100 {
	width: 100%;
}
.disabled {
	color: rgba(0, 0, 0, 0.65);
	background-color: white;
}
</style>
