<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-row>
                <a-col :span="8">
                    <a-card>
                        <a-row>
                            <a-button
                                    :type="'primary'"
                                    class="btn"
                                    @click="createss"
                                    v-if="isGranted('sl_ss_add')"
                            >
                                <a-icon type="plus"/>
                                <span>{{ l("Create") }}</span>
                            </a-button>
                            <a-button
                                    :type="'primary'"
                                    class="btn"
                                    @click="editss"
                                    v-if="isGranted('sl_ss_edit')"
                            >
                                <a-icon type="edit"/>
                                <span>{{ l("Edit") }}</span>
                            </a-button>
                            <a-button
                                    class="btn"
                                    :type="'danger'"
                                    v-if="isGranted('sl_ss_delete')"
                                    @click="deletess"
                            >
                                <a-icon type="delete"/>
                                <span>{{ l("Delete") }}</span>
                            </a-button>
                        </a-row>
                        <a-row style="margin-top: 5px">
                            <a-col :span="24" style="text-align: right">
                                <a-input-search
                                        name="filterText"
                                        style="width: 200px"
                                        :placeholder="l('SearchWithThreeDot')"
                                        enterButton
                                        v-model="filterText"
                                        @search="searchChange"
                                />
                            </a-col>
                        </a-row>
                        <div style="margin-top: 10px">
                            <a-table
                                    class="list-table"
                                    :pagination="false"
                                    :customRow="customRow"
                                    :columns="columns"
                                    :rowKey="tableDatas => tableDatas.id"
                                    :dataSource="tableData">
                                <a-checkbox slot="checkbox"
                                            slot-scope="text, record"

                                            :checked="record.checked">
                                </a-checkbox>
                            </a-table>
                            <a-pagination
                                    class="pagination"
                                    :total="totalItems"
                                    :showTotal="showTotalFun"
                                    v-model="pageNumber"
                                    @change="onChange"/>
                        </div>
                    </a-card>
                </a-col>
                <a-col :span="16" style="padding-left: 10px">
                    <a-card>
                        <a-row>
                            <a-button
                                    v-if="isGranted('sl_ss_detail_edit')"
                                    :type="'primary'"
                                    class="btn"
                                    @click="editDetail"
                            >
                                <a-icon type="edit"/>
                                <span>{{ l("Edit") }}</span>
                            </a-button>
                        </a-row>
                        <div style="margin-top: 10px;">
                            <a-table
                                    @change="sorterChange"
                                    class="list-table"
                                    :pagination="false"
                                    :scroll="{ x: 800,y:scroll_y }"
                                    :customRow="customRow2"
                                    :columns="columns2"
                                    :rowKey="tableDatas2 => tableDatas2.id"
                                    :dataSource="tableData2">
                                <a-checkbox slot="checkbox"
                                            slot-scope="text, record"

                                            :checked="record.checked">
                                </a-checkbox>
                                <span slot="rate" slot-scope="text,record">
                                    {{text}}%
                                </span>
                            </a-table>
                            <a-pagination
                                    class="pagination"
                                    :total="totalItems2"
                                    :showTotal="showTotalFun2"
                                    v-model="pageNumber2"
                                    @change="onChange2"/>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
        </a-spin>
    </a-config-provider>
</template>

<script>
    // 社保规则页面
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {
        SocialSecurityCategoryServiceProxy,
        SocialSecurityDetailServiceProxy
    } from "../../../shared/service-proxies";
    import CreateOrEditSsRules from './create-or-edit-ss-rules/create-or-edit-ss-rules'
    import CreateOrEditSsDetail from './create-or-edit-ss-detail/create-or-edit-ss-detail'
    import modalHelper from "../../../shared/helpers/modal/modal-helper";

    let _this;
    export default {
        name: "ss-rules",
        mixins: [AppComponentBase],
        components: {CreateOrEditSsRules},
        data() {
            return {
                zh_CN,
                //表格上方间隔
                defaultTop: 30,
                // 总数
                totalItems: 0,
                totalItems2: 0,
                // 当前页码
                pageNumber: 1,
                pageNumber2: 1,
                // 共多少页
                totalPages: 1,
                totalPages2: 1,
                // 条数显示范围
                pagerange: [1, 1],
                filterText: undefined,
                request: {sorting: "", maxResultCount: 10, skipCount: 0},
                request2: {sorting: "", maxResultCount: 10, skipCount: 0},
                columns: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: this.l("Name"),
                        dataIndex: "name",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "name"}
                    },
                    {
                        title: this.l("编码"),
                        dataIndex: "code",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "code"}
                    },
                ],
                columns2: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: this.l("Name"),
                        dataIndex: "name",
                        width: 120,
                        sorter: true,
                        fixed: 'left',
                        ellipsis: true,
                        align: "center",
                    },
                    {
                        title: this.l("编码"),
                        dataIndex: "code",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                    },

                    {
                        title: '比例',
                        dataIndex: "rate",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "rate"}
                    },
                    {
                        title: '调整金额',
                        dataIndex: "adjustAmount",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                    },
                    // {
                    //     title: '上限',
                    //     dataIndex: "upperLimit",
                    //     sorter: false,
                    //     ellipsis: true,
                    //     align: "center",
                    // },
                    // {
                    //     title: '下限',
                    //     dataIndex: "lowerLlimit",
                    //     sorter: false,
                    //     ellipsis: true,
                    //     align: "center",
                    // },
                    {
                        title: '备注',
                        dataIndex: "remark",
                        sorter: false,
                        ellipsis: true,
                        align: "center",
                    },
                ],
                tableData: [],
                tableData2: [],
                socialId: undefined,

                //明细ID
                detailId: undefined,
            }
        },
        created() {
            this._socialSecurityCategoryServiceProxy = new SocialSecurityCategoryServiceProxy(this.$apiUrl, this.$api);
            this._socialSecurityDetailServiceProxy = new SocialSecurityDetailServiceProxy(this.$apiUrl, this.$api);

        },
        mounted() {
            _this = this;
            this.getData();
        },
        methods: {
            searchChange() {
                this.request.skipCount = 0;
                this.pageNumber = 1;
                this.getData();
            },
            getData() {
                this.loading = true;
                this._socialSecurityCategoryServiceProxy.getPaged(
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                    .finally(() => {
                        this.loading = false
                    })
                    .then(res => {
                        this.tableData = res.items;
                        this.totalItems = res.totalCount;
                        this.totalPages = Math.ceil(
                            res.totalCount / this.request.maxResultCount
                        );
                        if (this.tableData.length > 0) {
                            this.select(this.tableData[0]);
                        }
                        else {
                            this.tableData2=[];
                            this.detailId=undefined;
                        }
                    })
            },
            getData2(){
                this.loading = true;
                this._socialSecurityDetailServiceProxy.getPaged(this.socialId, undefined, this.request2.sorting, this.request2.maxResultCount, this.request2.skipCount)
                    .then(res=>{
                        this.tableData2 = res.items;
                        this.totalItems2 = res.totalCount;
                        this.totalPages2 = Math.ceil(
                            res.totalCount / this.request2.maxResultCount
                        );
                        if (this.tableData2.length > 0) {
                            this.select2(this.tableData2[0]);
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
            },

            /**
             * 刷新时的模拟选择
             */
            select(rows) {
                let row = this.tableData.find(item => item.id == rows.id);
                this.socialId = rows.id;
                row.checked = true;
                _this.$forceUpdate();

                this.getData2();
            },
            select2(rows){
                let row = this.tableData2.find(item => item.id == rows.id);
                this.detailId = rows.id;
                row.checked = true;
                _this.$forceUpdate();
            },
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({

                on: {
                    click: () => {
                        _this.socialId = record.id;
                        //清空选择
                        _this.tableData.map(item => item.checked = false);
                        //赋值选择
                        record.checked = !record.checked;
                        //强制更新布局
                        _this.$forceUpdate();

                        _this.getData2();
                    }
                }
            }),
            customRow2: (record, index) => ({

                on: {
                    click: () => {
                        _this.detailId = record.id;
                        //清空选择
                        _this.tableData2.map(item => item.checked = false);
                        //赋值选择
                        record.checked = !record.checked;
                        //强制更新布局
                        _this.$forceUpdate();
                    }
                }
            }),
            /**
             * 分页事件
             */
            showTotalFun() {
                return `共有${this.totalItems}条`;
            },
            showTotalFun2() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber2,
                    this.totalPages2,
                    this.totalItems2,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            onChange2(page, pageSize) {
                this.pageNumber2 = page;
                this.request2.skipCount = (page - 1) * this.request2.maxResultCount;
                this.getData2();
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request2.skipCount=0;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request2.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
                } else {
                    this.request2.sorting = undefined;
                }
                this.getData2();
            },
            /**
             * 添加社保类别
             */
            createss() {
                modalHelper.create(
                    CreateOrEditSsRules,
                    {},
                    {width: "500px"}
                )
                    .subscribe(res => {
                        if (res) {
                            this.searchChange();
                        }
                    });
            },
            /**
             * 编辑社保类别
             * @returns {any}
             */
            editss() {
                if (!this.socialId) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                modalHelper.create(
                    CreateOrEditSsRules,
                    {
                        id: this.socialId
                    },
                    {width: "500px"}
                )
                    .subscribe(res => {
                        if (res) {
                            this.searchChange();
                        }
                    });
            },
            /**
             * 删除社保类别
             */
            deletess(){
                if (!this.socialId) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                this.$confirm({
                    title:"确认操作",
                    content:"你确认要删除该社保类别吗？",
                    okText:"确认",
                    cancelText:"取消",
                    onOk:()=>{
                        this.loading=true;
                        this._socialSecurityCategoryServiceProxy.delete(this.socialId)
                        .finally(()=>{this.loading=true;})
                        .then(res=>{
                            this.$notification["success"]({
                                message: this.l("SuccessfullyDeleted")
                            });
                            this.socialId=undefined;
                            this.searchChange();
                        })
                    }
                });
            },
            /**
             * 编辑社保明细
             */
            editDetail(){
                if(!this.detailId){
                    abp.message.warn('请选择一条社保明细')
                    return
                }
                modalHelper.create(
                    CreateOrEditSsDetail,
                    {
                        id: this.detailId,
                        ssId: this.socialId
                    },
                    {width: "500px"}
                )
                    .subscribe(res => {
                        if (res) {
                            this.searchChange();
                        }
                    });
            },
        }
    }
</script>

<style scoped>
    .btn {
        margin: 2px 2px;
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
